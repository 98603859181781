"use client"
import React, { createContext, useContext, useEffect, useState } from "react"

type BrowserNameContextValue = {
  name: BrowserName
}

export enum BrowserName {
  Chrome = "Chrome",
  Safari = "Safari",
  Opera = "Opera",
  Firefox = "Firefox",
  IE = "IE",
  Edge = "Edge",
  Unknown = "Unknown",
}

export const getBrowserName = (userAgent: string = navigator.userAgent): BrowserName => {
  let browser = BrowserName.Unknown

  if (userAgent.indexOf("Edg") > -1) {
    browser = BrowserName.Edge
  } else if (userAgent.indexOf("Chrome") > -1) {
    browser = BrowserName.Chrome
  } else if (userAgent.indexOf("Safari") > -1) {
    browser = BrowserName.Safari
  } else if (userAgent.indexOf("Opera") > -1) {
    browser = BrowserName.Opera
  } else if (userAgent.indexOf("Firefox") > -1) {
    browser = BrowserName.Firefox
  } else if (userAgent.indexOf("MSIE") > -1 || userAgent.indexOf("Trident") > -1) {
    browser = BrowserName.IE //covers Internet Explorer 11
  }

  return browser
}

export const BrowserNameContext = createContext<BrowserNameContextValue | null>(null)

const BrowserNameProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [browserName, setBrowserName] = useState<BrowserName>(BrowserName.Unknown)

  useEffect(() => {
    setBrowserName(getBrowserName(navigator.userAgent))
  }, [browserName, setBrowserName])

  return (
    <BrowserNameContext.Provider value={{ name: browserName }}>
      {children}
    </BrowserNameContext.Provider>
  )
}

export const useBrowserName = (): BrowserName => {
  const context = useContext(BrowserNameContext)
  if (!context) {
    throw new Error("To use 'useBrowserName', you must implement the BrowserNameProvider component")
  }

  return context.name
}

export default BrowserNameProvider
