"use client"

import { ArrowLineUpRight, LegoSmiley } from "@phosphor-icons/react"
import NextLink from "app/projects/[projectId]/routing/_components/NextLink"
import useIsSandbox from "components/sandbox/useIsSandbox"
import { Button } from "src/orca/components/Button/Button"
import { Typography } from "src/orca/components/Typography/typography"

const SandboxBanner = () => {
  const isSandbox = useIsSandbox()

  if (!isSandbox) {
    return null
  }

  return (
    <div className="flex justify-center items-center gap-tokens-space-12 h-tokens-height-40 bg-tokens-surface-toast-sandbox">
      <LegoSmiley size={20} />
      <Typography variant="ui14" weight="regular">
        You are in Sandbox mode. No real changes on the production will be processed.
      </Typography>
      <NextLink href="https://docs.processout.com/docs/testing-in-the-sandbox" target="_blank">
        <Button size={32} variant="ghost" rightIcon={<ArrowLineUpRight />}>
          Learn more
        </Button>
      </NextLink>
    </div>
  )
}

export default SandboxBanner
