import config from "src/config"

export const REASONS = {
  NOT_AUTHORISED: "NOT_AUTHORISED",
  AUTHENTICATION_TIMEOUT: "AUTHENTICATION_TIMEOUT",
  ORGANIZATION_TIMEOUT: "ORGANIZATION_TIMEOUT",
  MISSING_ORG: "MISSING_ORG",
  MISSING_SSO_TOKENS: "MISSING_SSO_TOKENS",
  SSO_SETTINGS_FAILED: "SSO_SETTINGS_FAILED",
} as const

export type Reason = keyof typeof REASONS

const signOutUrl = ({ currentUrl, reason }: { currentUrl?: URL; reason?: Reason } = {}) => {
  const path = reason !== "NOT_AUTHORISED" ? "/auth/sign-out" : "/auth/sign-in"
  const url = new URL(path, config().client.dashboardBaseUrl)

  if (reason && reason !== "NOT_AUTHORISED") {
    url.searchParams.set("reason", reason)
  }

  if (currentUrl) {
    const redirectUrl = new URL(currentUrl.pathname, config().client.dashboardBaseUrl)
    redirectUrl.search = currentUrl.search
    url.searchParams.set("callbackUrl", redirectUrl.toString())
  }

  return url.toString()
}

export default signOutUrl
