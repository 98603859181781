export const THEME_COOKIE_NAME = "dashboard-theme"
export const SYSTEM_THEME_COOKIE_NAME = "system-theme"

export type Theme = "dark" | "light" | "system-light" | "system-dark"

export const THEME_TO_CLASSNAME_MAP: Record<Theme, string> = {
  dark: "dark",
  light: "",
  "system-dark": "dark",
  "system-light": "",
}
