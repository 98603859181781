"use client"

import { Locales } from "@types"
import React, { createContext, useContext } from "react"

type LocalesContext = {
  locales: Locales
}

export type LocalesProviderProps = LocalesContext

export const LocalesContext = createContext<LocalesContext | null>(null)

const LocalesProvider: React.FC<React.PropsWithChildren<LocalesProviderProps>> = ({
  children,
  locales,
}) => {
  return <LocalesContext.Provider value={{ locales }}>{children}</LocalesContext.Provider>
}

export const useLocales = (): Locales => {
  const context = useContext(LocalesContext)
  if (!context) {
    throw new Error("To use 'useLocales', you must implement the LocalesProvider component")
  }

  return context.locales
}

export default LocalesProvider
