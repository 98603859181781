import { boolean, InferType, number, object, string } from "yup"

const clientConfigSchema = object({
  appVersion: string(),
  cdnAssetPrefix: string(),
  dashboardBaseUrl: string().required(),
  ddRum: object({
    enable: boolean().required(),
    sampleRate: number().required(),
  }).required(),
  env: string(),
  launchDarklySideId: string().required(),
  posthogKey: string(),
  processOutJsUrl: string().required(),
  segmentWriteKey: string().required(),
  trackers: string().required(),
  verbose: boolean(),
})

const serverConfigSchema = object({
  cognitoClientId: string().required(),
  cognitoClientSecret: string().required(),
  cognitoEndpoint: string(),
  cognitoIssuer: string().required(),
  cognitoRegion: string().required(),
  cubeApiUrl: string().required(),
  cubeSecret: string().required(),
  cookieSharedDomain: string().required(),
  jwtPKCS8: string().required(),
  launchDarklySdkKey: string().required(),
  logLevel: string().required(),
  nextAuthSecret: string().required(),
  poApiBaseUrl: string().required(),
  hppDomain: string().required(),
  hppApiSecret: string().required(),
}).concat(clientConfigSchema)

export type ClientConfig = InferType<typeof clientConfigSchema>
export type ServerConfig = InferType<typeof serverConfigSchema>

const defaultEnv = {
  NEXT_PUBLIC_APP_VERSION: process.env.NEXT_PUBLIC_APP_VERSION,
  NEXT_PUBLIC_CDN_ASSET_PREFIX: process.env.NEXT_PUBLIC_CDN_ASSET_PREFIX,
  NEXT_PUBLIC_DASHBOARD_BASE_URL: process.env.NEXT_PUBLIC_DASHBOARD_BASE_URL,
  NEXT_PUBLIC_DDRUM_ENABLE: process.env.NEXT_PUBLIC_DDRUM_ENABLE,
  NEXT_PUBLIC_DDRUM_SAMPLE_RATE: process.env.NEXT_PUBLIC_DDRUM_SAMPLE_RATE,
  NEXT_PUBLIC_ENV: process.env.NEXT_PUBLIC_ENV,
  NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_ID: process.env.NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_ID,
  NEXT_PUBLIC_POSTHOG_KEY: process.env.NEXT_PUBLIC_POSTHOG_KEY,
  NEXT_PUBLIC_PROCESSOUT_JS_URL: process.env.NEXT_PUBLIC_PROCESSOUT_JS_URL,
  NEXT_PUBLIC_SEGMENT_WRITE_KEY: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
  NEXT_PUBLIC_TRACKERS: process.env.NEXT_PUBLIC_TRACKERS,
  NEXT_PUBLIC_VERBOSE: process.env.NEXT_PUBLIC_VERBOSE,
  COGNITO_CLIENT_ID: process.env.COGNITO_CLIENT_ID,
  COGNITO_DASHBOARD_NEXT_CLIENT_SECRET: process.env.COGNITO_DASHBOARD_NEXT_CLIENT_SECRET,
  COGNITO_REGION: process.env.COGNITO_REGION,
  COGNITO_ISSUER: process.env.COGNITO_ISSUER,
  COGNITO_LOCAL_ENDPOINT: process.env.COGNITO_LOCAL_ENDPOINT,
  COOKIE_SHARED_DOMAIN: process.env.COOKIE_SHARED_DOMAIN,
  CUBE_API_URL: process.env.CUBE_API_URL,
  CUBE_SECRET: process.env.CUBE_SECRET,
  JWT_PKCS8: process.env.JWT_PKCS8,
  LAUNCHDARKLY_SDK_KEY: process.env.LAUNCHDARKLY_SDK_KEY,
  LOG_LEVEL: process.env.LOG_LEVEL,
  NEXTAUTH_SECRET: process.env.NEXTAUTH_SECRET,
  PO_API_BASE_URL: process.env.PO_API_BASE_URL,
  HPP_BASE_URL: process.env.HPP_BASE_URL,
  HPP_API_SECRET: process.env.HPP_API_SECRET,
} as Record<string, string>

interface ConfigInterface {
  validate(): void
  readonly server: ServerConfig
  readonly client: ClientConfig
}

class Config implements ConfigInterface {
  private env: Record<string, string> = {}
  private static instance: Config | null = null
  private clientConfig: ClientConfig | null = null
  private serverConfig: ServerConfig | null = null

  constructor(env: Record<string, string>) {
    this.env = env
  }

  /**
   * This method is used to override the environment variables during tests
   * @param env
   * @param __dangerouslyResetConfig - This is here to signify to the engineers that this isn't something we should be using
   */
  static initialize({
    env,
  }: {
    env: Record<string, string>
    __dangerouslyResetConfig: true
  }): Config {
    Config.instance = new Config(env)
    return Config.instance
  }

  static getInstance(): Config {
    if (!Config.instance) {
      Config.instance = new Config(defaultEnv)
    }

    return Config.instance
  }

  validate() {
    serverConfigSchema.validateSync(this.server, { abortEarly: false })
  }

  get client(): ClientConfig {
    if (!this.clientConfig) {
      this.clientConfig = {
        appVersion: this.env.NEXT_PUBLIC_APP_VERSION ?? "vUnknown",
        cdnAssetPrefix: this.env.NEXT_PUBLIC_CDN_ASSET_PREFIX ?? "",
        dashboardBaseUrl: this.env.NEXT_PUBLIC_DASHBOARD_BASE_URL,
        ddRum: {
          enable: this.env.NEXT_PUBLIC_DDRUM_ENABLE === "true",
          sampleRate: parseInt(this.env.NEXT_PUBLIC_DDRUM_SAMPLE_RATE ?? "50", 10),
        },
        env: this.env.NEXT_PUBLIC_ENV ?? "unknown",
        launchDarklySideId: this.env.NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_ID,
        posthogKey: this.env.NEXT_PUBLIC_POSTHOG_KEY,
        processOutJsUrl: this.env.NEXT_PUBLIC_PROCESSOUT_JS_URL,
        segmentWriteKey: this.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
        trackers: this.env.NEXT_PUBLIC_TRACKERS,
        verbose: this.env.NEXT_PUBLIC_VERBOSE === "true",
      }
    }

    return this.clientConfig
  }

  get server(): ServerConfig {
    if (!this.serverConfig) {
      this.serverConfig = {
        ...this.client,
        cognitoClientId: this.env.COGNITO_CLIENT_ID,
        cognitoClientSecret: this.env.COGNITO_DASHBOARD_NEXT_CLIENT_SECRET,
        cognitoRegion: this.env.COGNITO_REGION,
        cognitoIssuer: this.env.COGNITO_ISSUER,
        cognitoEndpoint: this.env.COGNITO_LOCAL_ENDPOINT || undefined,
        cookieSharedDomain: this.env.COOKIE_SHARED_DOMAIN,
        cubeApiUrl: this.env.CUBE_API_URL,
        cubeSecret: this.env.CUBE_SECRET,
        jwtPKCS8: this.env.JWT_PKCS8,
        launchDarklySdkKey: this.env.LAUNCHDARKLY_SDK_KEY,
        logLevel: this.env.LOG_LEVEL ?? "info",
        nextAuthSecret: this.env.NEXTAUTH_SECRET,
        poApiBaseUrl: this.env.PO_API_BASE_URL,
        hppDomain: this.env.HPP_BASE_URL,
        hppApiSecret: this.env.HPP_API_SECRET,
      }
    }

    return this.serverConfig
  }
}

export const init = Config.initialize
const config = () => Config.getInstance()

export default config
