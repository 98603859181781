import(/* webpackMode: "eager" */ "/home/runner/work/dashboard-next/dashboard-next/node_modules/next/font/google/target.css?{\"path\":\"src/app/layout.tsx\",\"import\":\"Work_Sans\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-workSans\",\"adjustFontFallback\":false,\"style\":\"normal\"}],\"variableName\":\"workSans\"}");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/dashboard-next/dashboard-next/src/app/_components/RootProviders.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/dashboard-next/dashboard-next/src/app/_components/SandboxBanner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/dashboard-next/dashboard-next/src/app/_components/SessionWrapper.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/dashboard-next/dashboard-next/src/components/sandbox/SetSandboxClass.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/dashboard-next/dashboard-next/src/components/Timezone/SetTimezone.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/home/runner/work/dashboard-next/dashboard-next/src/orca/components/Toast/Toast.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/dashboard-next/dashboard-next/src/styles/globals.css");
