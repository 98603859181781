"use client"
import Link, { LinkProps } from "next/link"
import { useRouter } from "next/navigation"
import { ReactNode, useState } from "react"

type Props = LinkProps & {
  tabIndex?: number
  children: ReactNode
  className?: string
  target?: string
  "aria-label"?: string
}

export default function NextLink({ children, ...linkProps }: Props) {
  const router = useRouter()
  const [shouldPrefetch, setShouldPrefetch] = useState(true)

  const onMouseEnter = () => {
    if (shouldPrefetch) {
      setShouldPrefetch(false)
      router.prefetch(linkProps.href as string, {
        // @ts-ignore
        kind: "temporary", // To prevent doubled prefetching as router prefetching is not ideal in Next.js v13 yet
      })
    }
  }

  return (
    <Link prefetch={false} onMouseEnter={onMouseEnter} {...linkProps}>
      {children}
    </Link>
  )
}
