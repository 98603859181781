"use client"

import { useEffect } from "react"

export default function SetTimezone() {
  useEffect(() => {
    const timezone = new Intl.DateTimeFormat().resolvedOptions().timeZone
    const hasTimezoneCookie = document.cookie
      .split("; ")
      .some(cookie => cookie.startsWith("user-timezone="))

    if (!hasTimezoneCookie) {
      const expiryDate = new Date()
      expiryDate.setTime(expiryDate.getTime() + 24 * 60 * 60 * 1000)
      const expires = "expires=" + expiryDate.toUTCString()

      document.cookie = `user-timezone=${timezone}; ${expires}; path=/; secure; samesite=strict;`
    }
  }, [])

  return null
}
