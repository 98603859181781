"use client"

import {
  CheckCircle,
  IconContext,
  Info,
  ShieldWarning,
  WarningOctagon,
  X,
} from "@phosphor-icons/react"
import { cva, VariantProps } from "class-variance-authority"
import { FC, ReactNode } from "react"
import {
  Slide,
  ToastContainer,
  ToastContent,
  toast as toastify,
  ToastOptions,
} from "react-toastify"
import { cn } from "src/lib/utils"
import { Button } from "src/orca/components/Button/Button"
import { ButtonIcon } from "src/orca/components/ButtonIcon/ButtonIcon"
import { Typography } from "src/orca/components/Typography/typography"

export const toastVariants = cva(
  "px-tokens-space-6 py-tokens-space-4 shadow-tokens-shadow-06 rounded-tokens-tip border-tokens-1",
  {
    variants: {
      variant: {
        default:
          "bg-tokens-surface-toast-default text-tokens-text-ontip-default border-tokens-border-button-toastbuttonondefault",
        attention:
          "bg-tokens-surface-toast-sandbox text-tokens-text-ontip-sandbox border-tokens-border-button-toastbuttononcolor",
        informative:
          "bg-tokens-surface-toast-informative text-tokens-text-ontip-informative border-tokens-border-button-toastbuttononcolor",
        positive:
          "bg-tokens-surface-toast-positive text-tokens-text-ontip-positive border-tokens-border-button-toastbuttononcolor",
        error:
          "bg-tokens-surface-toast-error text-tokens-text-ontip-error border-tokens-border-button-toastbuttononcolor",
      },
    },
    defaultVariants: {
      variant: "default",
    },
  },
)

export type ToastVariants = VariantProps<typeof toastVariants>

type TextButton = { text: string; isCloseButton?: boolean; onClick?: () => void }
type IconButton = { icon: ReactNode; isCloseButton?: boolean; onClick?: () => void }

export type ToastProps = {
  variant?: ToastVariants["variant"]
  iconLeft?: boolean
  headerText: string
  description?: string
  className?: string
  textButtons?: TextButton[]
  iconButtons?: IconButton[]
  autoClose?: number | false
  showCloseButton?: boolean
  id?: string
}

const toast: FC<ToastProps> = ({
  variant = "default",
  iconLeft = true,
  headerText,
  description,
  className,
  textButtons,
  iconButtons,
  autoClose = 3500,
  showCloseButton = true,
  id,
}) => {
  const getIcon = () => {
    switch (variant) {
      case "attention":
        return <ShieldWarning />
      case "positive":
        return <CheckCircle />
      case "error":
        return <WarningOctagon />
      default:
        return <Info />
    }
  }
  const buttonClasses = cn(
    "!text-inherit active:!bg-tokens-surface-toast-button-pressed-ondefault",
    variant === "default"
      ? "hover:bg-tokens-surface-toast-button-hover-ondefault"
      : "hover:bg-tokens-surface-toast-button-hover-oncolor",
  )

  const trigger =
    id && toastify.isActive(id)
      ? <TData = unknown,>(render: ToastContent<TData>, options: ToastOptions<TData>) => {
          toastify.update(id, {
            ...options,
            render,
          })
          return id
        }
      : toastify

  return trigger(
    ({ closeToast }) => (
      <div className="flex w-full gap-tokens-space-4">
        <div className="flex grow basis-full">
          {iconLeft && (
            <div className="flex min-w-tokens-space-40 justify-end py-tokens-space-12">
              <IconContext.Provider value={{ size: 28 }}>{getIcon()}</IconContext.Provider>
            </div>
          )}
          <div className="flex flex-col py-tokens-space-16 pl-tokens-space-16 pr-tokens-space-40 gap-tokens-space-12">
            <Typography variant="ui16" weight="medium" color="inherit">
              {headerText}
            </Typography>
            {description && (
              <Typography variant="ui13" weight="medium" color="inherit">
                {description}
              </Typography>
            )}
          </div>
        </div>
        {((textButtons && textButtons.length > 0) || !autoClose) && (
          <div
            className={cn(
              "flex justify-center items-center gap-tokens-space-4 pl-tokens-space-8 pr-tokens-space-2 border-l-tokens-1",
              variant === "default"
                ? "border-tokens-border-button-toastbuttonondefault"
                : "border-tokens-border-button-toastbuttononcolor",
            )}
          >
            {textButtons?.map(({ text, isCloseButton, onClick }, index) => (
              <Button
                key={index}
                variant="ghost"
                onClick={() => {
                  isCloseButton ? closeToast() : onClick?.()
                }}
                className={buttonClasses}
              >
                {text}
              </Button>
            ))}
            {iconButtons?.map(({ icon, isCloseButton, onClick }, index) => (
              <ButtonIcon
                key={index}
                icon={icon}
                variant="ghost"
                onClick={() => {
                  isCloseButton ? closeToast() : onClick?.()
                }}
                className={buttonClasses}
              />
            ))}

            {showCloseButton && (
              <ButtonIcon
                variant="ghost"
                icon={<X />}
                onClick={closeToast}
                className={buttonClasses}
                aria-label="Close toast"
              />
            )}
          </div>
        )}
      </div>
    ),
    {
      autoClose: autoClose,
      closeButton: false,
      className: cn(
        toastVariants({ variant }),
        // default width, can be overwritten
        "w-[680px]",
        className,
      ),
      toastId: id,
    },
  )
}

const Toaster = () => {
  return <ToastContainer position="bottom-center" hideProgressBar stacked transition={Slide} />
}

export { toast, Toaster }
