import { Info, Warning, X } from "@phosphor-icons/react"
import { CheckCircle } from "@phosphor-icons/react/dist/ssr"
import { Action, Close, Description, Root, Title } from "@radix-ui/react-toast"
import { FC, createContext } from "react"
import "src/styles/components/Toast.css"

export const ToastContext = createContext<{
  toasts: ToastProps[]
  addToast: (newToast: ToastProps) => void
}>({
  toasts: [],
  addToast: () => {},
})

export enum ToastVariants {
  NEUTRAL = "neutral",
  SUCCESS = "success",
  WARNING = "warning",
  ERROR = "error",
  INFO = "info",
}

// default duration is 5000 miliseconds (built into Root)
export type ToastProps = {
  variant: ToastVariants
  duration?: number
  title?: string
  description?: string
  closeButton?: boolean
  actionButton?: { text: string; action: () => void }
  showIcon?: boolean
}

/**
 * @deprecated use src/orca/components/Toast.tsx instead
 */
export const Toast: FC<ToastProps> = ({
  variant,
  duration,
  title,
  description,
  closeButton = true,
  actionButton,
  showIcon = true,
}) => {
  const iconMap: Partial<Record<ToastVariants, JSX.Element>> = {
    [ToastVariants.SUCCESS]: <CheckCircle />,
    [ToastVariants.WARNING]: <Warning />,
    [ToastVariants.ERROR]: <Info />,
    [ToastVariants.INFO]: <Info />,
  }

  const getIcon = (variant: ToastVariants) => {
    if (variant === ToastVariants.NEUTRAL) {
      return null
    }
    const IconComponent = iconMap[variant]
    return IconComponent ? <div className="toast-icon">{IconComponent}</div> : null
  }
  return (
    <Root
      className={`toast-root toast-root-${variant}`}
      duration={duration}
      data-testid="toast-root"
    >
      {showIcon && getIcon(variant)}
      <div className="toast-text">
        {title && <Title className="toast-title">{title}</Title>}
        {description && <Description className="toast-description">{description}</Description>}
        {actionButton && (
          <Action
            altText={actionButton.text}
            onClick={actionButton.action}
            className="toast-action"
          >
            {actionButton.text}
          </Action>
        )}
      </div>
      {closeButton && (
        <Close aria-label="Close" data-testid="toast-close" className="toast-close-button">
          <X size="1rem" className="toast-close-icon" aria-hidden />
        </Close>
      )}
    </Root>
  )
}
