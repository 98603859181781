"use client"

import { SessionProvider, signOut, useSession } from "next-auth/react"
import { usePathname } from "next/navigation"
import React, { FunctionComponent, useEffect } from "react"
import signOutUrl, { Reason } from "utils/signOutUrl"

const SessionWrapper = ({ children }: { children: React.ReactNode }) => {
  const { data, status } = useSession()
  const pathname = usePathname()

  useEffect(() => {
    if (status !== "loading" && data?.error) {
      void signOut({
        redirect: !pathname.startsWith("/auth/"),
        callbackUrl: signOutUrl({
          reason: data.error as Reason,
          currentUrl: new URL(pathname, window.location.origin),
        }),
      })
    }
  }, [status, data])

  return children
}

const withSessionProvider = <P extends object>(
  Component: FunctionComponent<P>,
): FunctionComponent<P> => {
  const hoc: FunctionComponent<P> = (props: P) => (
    <SessionProvider>
      <Component {...props} />
    </SessionProvider>
  )

  hoc.displayName = `withSessionProvider(${Component.displayName || Component.name || "unknown"})`

  return hoc
}

export default withSessionProvider(SessionWrapper)
