"use client"

import {
  SYSTEM_THEME_COOKIE_NAME,
  THEME_COOKIE_NAME,
  THEME_TO_CLASSNAME_MAP,
  Theme,
} from "components/theme/types"
import { deleteCookie, hasCookie, setCookie } from "cookies-next"
import React, { createContext, useCallback, useContext, useEffect, useState } from "react"
import { getDateOneYearFromNow } from "utils/utils.shared"

export type ThemeOptionsFromUX = Exclude<Theme | "system", "system-dark" | "system-light">
type SetTheme = (theme: ThemeOptionsFromUX) => void

type ThemeContextType = {
  theme: Theme
  setTheme: SetTheme
}

export type ThemeProviderProps = {
  // Undefined when no cookies can drive the value
  initialTheme: Theme | undefined
}

export const ThemeContext = createContext<ThemeContextType | null>(null)

const setCookieWithOptions = (key: string, value: string): void => {
  setCookie(key, value, {
    expires: getDateOneYearFromNow(),
    sameSite: "strict",
    secure: true,
    path: "/",
  })
}

const ThemeProvider: React.FC<React.PropsWithChildren<ThemeProviderProps>> = ({
  children,
  initialTheme,
}) => {
  const [theme, innerSetTheme] = useState<Theme | undefined>(initialTheme)

  const darkQueryChange = (event: MediaQueryListEvent) => {
    setCookieWithOptions(SYSTEM_THEME_COOKIE_NAME, event.matches ? "dark" : "light")

    if (!hasCookie(THEME_COOKIE_NAME)) {
      if (event.matches) {
        innerSetTheme("system-dark")
      } else {
        innerSetTheme("system-light")
      }
    }
  }

  useEffect(() => {
    const darkQuery = window.matchMedia("(prefers-color-scheme: dark)")
    // we want to listen if the appearance from the system is changing
    darkQuery.addEventListener("change", darkQueryChange)

    return () => darkQuery.removeEventListener("change", darkQueryChange)
  }, [])

  const setTheme: SetTheme = useCallback(theme => {
    if (theme === "system") {
      if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
        innerSetTheme("system-dark")
      } else {
        innerSetTheme("system-light")
      }
    } else {
      innerSetTheme(theme)
    }
  }, [])

  useEffect(() => {
    if (theme === undefined) {
      setTheme("system")
      return
    }

    const handleBodyClassUpdate = (newTheme: Theme) => {
      const classToAdd = THEME_TO_CLASSNAME_MAP[newTheme]
      const classesToRemove = Object.values(THEME_TO_CLASSNAME_MAP).filter(
        v => v !== "" && v !== classToAdd,
      )

      if (classesToRemove.length > 0) {
        document.body.classList.remove(...classesToRemove)
      }
      if (classToAdd !== "") {
        document.body.classList.add(classToAdd)
      }
    }

    handleBodyClassUpdate(theme)

    switch (theme) {
      case "light":
        setCookieWithOptions(THEME_COOKIE_NAME, "light")
        break
      case "dark":
        setCookieWithOptions(THEME_COOKIE_NAME, "dark")
        break
      case "system-light":
      case "system-dark":
      default:
        deleteCookie(THEME_COOKIE_NAME)
        // switch the theming based on match media query
        if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
          setCookieWithOptions(SYSTEM_THEME_COOKIE_NAME, "dark")
        } else {
          setCookieWithOptions(SYSTEM_THEME_COOKIE_NAME, "light")
        }
        break
    }
  }, [theme])

  // Theme is defaulted to "system-light" here if undefined, but it will be updated in the useEffect above based
  // on the user's system very quickly. And 99% of the time, users will have an initialTheme from cookies.
  return (
    <ThemeContext.Provider value={{ theme: theme ?? "system-light", setTheme }}>
      {children}
    </ThemeContext.Provider>
  )
}

export const useTheme = (): ThemeContextType => {
  const context = useContext(ThemeContext)
  if (!context) {
    throw new Error("To use 'useTheme', you must implement the ThemeProvider component")
  }

  return context
}

export default ThemeProvider
