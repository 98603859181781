export const SANDBOX_PROJECT_PREFIX = "test-"

export const getProjectIdWithoutSandboxPrefix = (projectId: string) => {
  if (isSandboxProject(projectId)) {
    return projectId.substring(SANDBOX_PROJECT_PREFIX.length)
  }

  return projectId
}

export const getProjectIdWithSandboxPrefix = (projectId: string) => {
  if (isSandboxProject(projectId)) {
    return projectId
  }

  return `${SANDBOX_PROJECT_PREFIX}${projectId}`
}

export const isSandboxProject = (projectId: string) => projectId.startsWith(SANDBOX_PROJECT_PREFIX)

const parentRedirectDict: Record<string, string> = Object.freeze({
  transactions: "transactions",
  customers: "transactions",
  reconciliation: "reconciliation",
  checkout: "checkout",
  charts: "charts",
  boards: "boards",
  events: "events",
})

const redirectAndTruncate = (segments: string[]): string[] => {
  const index = segments.findIndex(segment => parentRedirectDict[segment])

  if (index !== -1) {
    return segments.slice(0, index + 1).map(segment => parentRedirectDict[segment] || segment)
  }

  return segments
}

export const toggleSandboxURL = (pathname: string, searchParams?: string): string => {
  let segments = pathname.split("/")

  segments = segments.map(segment => {
    if (segment.startsWith("proj_")) {
      return segment.replace("proj_", "test-proj_")
    }
    if (segment.startsWith("test-proj_")) {
      return segment.replace("test-proj_", "proj_")
    }
    return segment
  })

  segments = redirectAndTruncate(segments)

  const updatedPath = segments.join("/")

  return searchParams ? `${updatedPath}?${searchParams}` : updatedPath
}
