"use client"

import { Provider as RadixToastProvider, Viewport as ToastViewport } from "@radix-ui/react-toast"
import * as RadTooltip from "@radix-ui/react-tooltip"
import BrowserNameProvider from "app/_components/BrowserNameProvider"
import ThemeProvider, { ThemeProviderProps } from "components/theme/ThemeProvider"
import React from "react"
import { Toast, ToastContext } from "src/components/Toast"
import { useToasts } from "src/components/Toast/_hooks/useToasts"
import "src/styles/components/Toast.css"
import { SWRConfig } from "swr"
import LocalesProvider, { LocalesProviderProps } from "./LocalesProvider"

export type RootProvidersProps = {
  themeProviderProps: ThemeProviderProps
  localesProviderProps: LocalesProviderProps
}

const RootProviders: React.FC<React.PropsWithChildren<RootProvidersProps>> = ({
  children,
  themeProviderProps,
  localesProviderProps,
}) => {
  const { toasts, addToast } = useToasts()

  return (
    <RadTooltip.Provider>
      <SWRConfig value={{ shouldRetryOnError: false }}>
        <ThemeProvider {...themeProviderProps}>
          <LocalesProvider {...localesProviderProps}>
            <BrowserNameProvider>
              <RadixToastProvider swipeDirection="right">
                <ToastContext.Provider value={{ toasts, addToast }}>
                  {children}
                  {toasts.map((toast, index) => (
                    <Toast key={index} {...toast} />
                  ))}
                  <ToastViewport className="toast-viewport" />
                </ToastContext.Provider>
              </RadixToastProvider>
            </BrowserNameProvider>
          </LocalesProvider>
        </ThemeProvider>
      </SWRConfig>
    </RadTooltip.Provider>
  )
}

export default RootProviders
