import { useState } from "react"
import { ToastProps } from "src/components/Toast"

export const useToasts = () => {
  const [toasts, setToasts] = useState<ToastProps[]>([])

  const addToast = (newToast: ToastProps) => {
    setToasts(prevToasts => [...prevToasts, newToast])
  }

  return { toasts, addToast }
}
