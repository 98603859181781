"use client"

import useIsSandbox from "components/sandbox/useIsSandbox"
import React, { useEffect } from "react"

const SetSandboxClass: React.FC = () => {
  const isSandbox = useIsSandbox()

  useEffect(() => {
    if (isSandbox) {
      document.body.classList.add("sandbox")
    } else {
      document.body.classList.remove("sandbox")
    }
  }, [isSandbox])

  return <></>
}

export default SetSandboxClass
