"use client"
import { ProjectIdParam } from "api/paramsTypes"
import { isSandboxProject } from "components/sandbox/utils"
import { useParams } from "next/navigation"

const useIsSandbox = (): boolean => {
  const params = useParams() as ProjectIdParam | null
  const projectId = params?.projectId

  return projectId ? isSandboxProject(projectId) : false
}

export default useIsSandbox
