"use client"

import { IconContext } from "@phosphor-icons/react"
import { Slot } from "@radix-ui/react-slot"
import { VariantProps } from "class-variance-authority"
import React, { forwardRef, PropsWithChildren, ReactNode } from "react"
import PendingCircle from "src/components/icon/PendingCircle"
import { cn } from "src/lib/utils"
import { buttonVariants } from "src/orca/components/Button/Button"

export type ButtonIconVariants = VariantProps<typeof buttonVariants>

export interface ButtonIconProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    ButtonIconVariants {
  icon: ReactNode
  loading?: boolean
  asChild?: boolean
  disabled?: boolean
  active?: boolean
}

export const ButtonIcon = forwardRef<HTMLButtonElement, PropsWithChildren<ButtonIconProps>>(
  (
    {
      type = "button",
      className,
      variant,
      icon,
      size = 40,
      asChild = false,
      loading = false,
      disabled,
      active,
      ...props
    },
    ref,
  ) => {
    const buttonIconSize = {
      32: "h-tokens-height-32 w-tokens-width-32",
      40: "h-tokens-height-40 w-tokens-width-40",
      48: "h-tokens-height-48 w-tokens-width-48",
    } as const

    const Comp = asChild ? Slot : "button"
    return (
      <Comp
        className={cn(
          buttonVariants({ variant, size: null, disabled }),
          size && buttonIconSize[size],
          "shrink-0",
          className,
        )}
        data-active={active}
        disabled={disabled || loading}
        ref={ref}
        type={type}
        {...props}
      >
        <IconContext.Provider value={{ size: size === 32 ? 16 : 20 }}>
          {loading ? <PendingCircle /> : icon}
        </IconContext.Provider>
      </Comp>
    )
  },
)

ButtonIcon.displayName = "ButtonIcon"
