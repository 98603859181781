import { CurrentProject, DeprecatedUser, ErrorResponse, Locales, UserPermissions } from "@types"
import { OrgManagerSchemas } from "src/utils/projectHierarchyApi"

export const getFormattedCurrency = (value: number, currency: string, locales: Locales): string => {
  return new Intl.NumberFormat(locales, {
    style: "currency",
    currency: currency,
  }).format(value)
}

export const getFormattedCount = (value: number, locales: Locales): string => {
  return new Intl.NumberFormat(locales).format(value)
}

export const getFormattedPercentage = (value: number, locales: Locales): string => {
  return new Intl.NumberFormat(locales, {
    style: "percent",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value)
}

export const getDateOneYearFromNow = (): Date => {
  const currentDate = new Date()

  return new Date(currentDate.setFullYear(currentDate.getFullYear() + 1))
}

export const isRecord = (value: unknown): value is Record<string, unknown> =>
  typeof value === "object" && !Array.isArray(value) && value !== null

export const isErrorResponse = (res: unknown): res is ErrorResponse => {
  return (
    isRecord(res) && "error" in res && isRecord(res.error) && typeof res.error.message === "string"
  )
}

export const chunk = (arr: Array<any>, n: number) => {
  const size = Math.ceil(arr.length / n)
  return Array.from({ length: n }, (v, i) => arr.slice(i * size, i * size + size))
}

export const userHasEditPermissionLevel = (
  user: DeprecatedUser,
  permission: keyof UserPermissions["permissions"],
): boolean => {
  return !(user.permissions[permission] !== "edit")
}

export const userHasNoPermissionLevel = (
  user: DeprecatedUser,
  permission: keyof UserPermissions["permissions"],
) => {
  return user.permissions[permission] === "none"
}

export const isCurrentProject = (project: any): project is CurrentProject => {
  return "defaultCurrency" in project
}

export const isOrgManagerProject = (project: any): project is OrgManagerSchemas["Project"] => {
  return "currency" in project
}

export const isSuperAdminOrg = (orgId?: string) => orgId === "org_00000000000000000000000000000000"
